import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-materialize";
import { Link } from "react-router-dom";
import ActividadDataInit from "./ActividadDataInit";
import moment from "moment";
import Icon from "../Icon";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export default function ActivityInfo({ id }) {
    const [info, setInfo] = useState(undefined);

    const [state, setState] = useState(undefined);

    const fetchData = async () => {
        const response = await axios.get("/actividad/" + id);

        if (response.status === 200) {
            setInfo(response.data);
            setState(response.data.estado_activo);
        }
    };

    const newState = async (id) => {
        const MySwal = withReactContent(Swal);
        const confirmed = await MySwal.fire({
            title: <p>Se avanzara el estado de la actividad</p>,
            showDenyButton: true,
            showCloseButton: true,
        });

        if (!confirmed.isConfirmed) {
            return false;
        }
        try {
            const response = await axios.post("/actividades/estado/next", {
                id: id,
            });

            if (response.data) {
                const estado = await axios.get(
                    "/actividad/estado/" + response.data
                );
                setState({ ...estado.data });
            }
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
        moment.locale("es");
    }, [id]);

    if (!info) {
        return <ProgressBar />;
    }

    const detalles = {
        Ref: info.id,
        ["Responsable CASA SEAT"]: info.responsable?.nombre ?? "",
        Modalidad: info.modalidad?.nombre ?? "",
        Estado: info.estados?.nombre ?? "",
        Acceso: info.acceso?.nombre ?? "",
        Nivel: info.top?.nombre ?? "",
        Patrocinio: info.patrocinio?.nombre ?? "",
        Espacio: info.espacios.map((e) => e.nombre).join(", "),
        Tematica: info.tematica?.nombre ?? "",
        ["Tipo de actividad"]: info.tipo?.nombre ?? "",
        ["Eje tematico"]: info.eje?.nombre,
        ["Tipo de publico"]: info.tipo?.nombre,
        Aforo: info.aforo,
        ["Publicación web"]: info.web == 1 ? "Sí" : "No",
        Responsable: info.empresa?.nombre ?? "",
        ["Coste CASA SEAT"]: info.coste_interno,
        ["Coste agencia"]: info.coste_agencia,
        ["Coste terceros"]: info.coste_externo,
        ["Coste terceros (Otros)"]: info.coste_externo_txt,
    };

    return (
        <div style={{ padding: "15px" }}>
            <div className="row">
                <div className="col s12">
                    <b>ID: </b> {id}
                    <Icon icon={info.top.icon} />
                </div>
                <div className="col s12 m6">
                    <Link to={"/actividad/" + id} className="sidenav-close">
                        <h5>
                            <i
                                className="material-icons"
                                style={{ transform: "rotate(90deg)" }}
                            >
                                ios_share
                            </i>
                            Ir a la actividad
                        </h5>
                    </Link>
                </div>
                <div className="col s12 m6" style={{ display: "flex" }}>
                    <h5>
                        Estado actual:{" "}
                        {state && (
                            <i
                                className={
                                    "material-icons cursor-pointer " +
                                    (state.color != ""
                                        ? state.color + "-text"
                                        : "")
                                }
                                onClick={() => {
                                    if (state.siguiente) {
                                        newState(id);
                                    }
                                }}
                            >
                                {state.icon}
                            </i>
                        )}
                    </h5>
                </div>
                <div className="col s12">
                    <h3>
                        <b>{info.nombre}</b>
                    </h3>
                    <hr></hr>
                </div>
            </div>
            <ActividadDataInit data={info} />
            <div className="row">
                <div className="col s12">
                    <h4>
                        <i className="material-icons">format_align_left</i>{" "}
                        Descripción
                    </h4>
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: info.descripcion }}
                    className="col s12"
                ></div>
                <div className="col s12">
                    <hr></hr>
                </div>
            </div>
            {info.ponente && (
                <div className="row">
                    <div className="col s12">
                        <h4>
                            <i className="material-icons">mic</i>Ponentes
                        </h4>
                        <p>{info.ponente}</p>
                    </div>
                    <div className="col s12">
                        <hr></hr>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col s12">
                    <h4>
                        <i className="material-icons">receipt</i>
                        Contraprestaciones
                    </h4>
                    <p>{info.contraprestaciones}</p>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <h4>
                        <i className="material-icons">info</i>Detalles
                    </h4>
                </div>
                <div className="col s12">
                    <TableDetalles detalles={detalles} />
                </div>
            </div>
        </div>
    );
}

export function TableDetalles({ detalles }) {
    return (
        <table className="highlight detalles-table">
            <tbody>
                {Object.entries(detalles).map(([k, v]) => {
                    if (typeof v === "string") {
                        return (
                            <tr key={k}>
                                <td>
                                    <span>{k}</span> :{" "}
                                    <div
                                        style={{ display: "inline-block" }}
                                        dangerouslySetInnerHTML={{ __html: v }}
                                    ></div>
                                </td>
                            </tr>
                        );
                    }
                    return;
                })}
            </tbody>
        </table>
    );
}
