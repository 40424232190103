import React from "react";
import "./dist/jquery.fileuploader.min.css";
import "./dist/jquery.fileuploader-theme-gallery.css";
import "./dist/font/font-fileuploader.css";
import UserContext from "../contextos/UserContext";
import axios from "axios";

const $ = window.jQuery;

let adicional = "";
/**
 * @data = data para enviar en el request POST formData
 * @onSubmit = función a ejecutar al terminar el request HTTP
 * @send = trigger para accionar el metodo uploadStart de Fileuploader API
 */
class Fileuploader extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            name: "files",
            options: {},
            send: false,
            total: 0,
            onSubmit: () => {
                return;
            },
            onError: () => {
                return;
            },
        };
        this.state.onSubmit = props.onSubmit ?? this.state.onSubmit;
        this.state.onError = props.onError ?? this.state.onError;
        this.state.send = props.send;
        if (props && Array.isArray(props)) {
            this.state.name = props[0];
            this.state.options = props[1];
        } else if (props && typeof props === "object") {
            this.state.name = props.name;
            for (var key in props) {
                var val = props[key];

                if (typeof val != "string") continue;
                if (
                    [
                        "limit",
                        "maxSize",
                        "fileMaxSize",
                        "theme",
                        "listInput",
                    ].indexOf(key) > -1
                )
                    this.state.options[key] = val;
                if ("extensions" == key)
                    this.state.options[key] = val.replace(/ /g, "").split(",");
                if ("files" == key) this.state.options[key] = JSON.parse(val);
            }
            if (props["disabled"]) this.state.options["limit"] = 0;
        }
    }

    componentDidMount() {
        const submitFunction = this.state.onSubmit;
        const onErrorFunction = this.state.onError;
        const token = this.context.user.token;
        const captions = this.props.captions ?? {};
        if (!this.el) return;
        let nFiles = 0;
        let totalFiles = 0;

        this.$el = $(this.el);
        this.$el.fileuploader(
            $.extend(this.state.options, {
                limit: 100,
                fileMaxSize: 200,
                changeInput: " ",
                theme: "gallery",
                enableApi: true,
                thumbnails: {
                    box:
                        '<div class="fileuploader-items">' +
                        '<ul class="fileuploader-items-list">' +
                        '<li class="fileuploader-input"><button type="button" class="fileuploader-input-inner"><i class="fileuploader-icon-main"></i> <span>${captions.feedback}</span></button></li>' +
                        "</ul>" +
                        "</div>",
                    item:
                        '<li class="fileuploader-item">' +
                        '<div class="fileuploader-item-inner">' +
                        '<div class="actions-holder">' +
                        '<button type="button" class="fileuploader-action fileuploader-action-sort is-hidden" title="${captions.sort}"><i class="fileuploader-icon-sort"></i></button>' +
                        '<button type="button" class="fileuploader-action fileuploader-action-settings is-hidden" title="${captions.edit}"><i class="fileuploader-icon-settings"></i></button>' +
                        '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                        '<div class="gallery-item-dropdown">' +
                        '<a class="fileuploader-action-popup">${captions.setting_edit}</a>' +
                        '<a class="gallery-action-rename">${captions.setting_rename}</a>' +
                        '<a class="gallery-action-asmain">${captions.setting_asMain}</a>' +
                        "</div>" +
                        "</div>" +
                        '<div class="thumbnail-holder">' +
                        "${image}" +
                        '<span class="fileuploader-action-popup"></span>' +
                        '<div class="progress-holder"><span></span>${progressBar}</div>' +
                        "</div>" +
                        '<div class="content-holder"><h5 title="${name}">${name}</h5><span>${size2}</span></div>' +
                        '<div class="type-holder">${icon}</div>' +
                        "</div>" +
                        "</li>",
                    item2:
                        '<li class="fileuploader-item file-main-${data.isMain}">' +
                        '<div class="fileuploader-item-inner">' +
                        '<div class="actions-holder">' +
                        '<button type="button" class="fileuploader-action fileuploader-action-sort" title="${captions.sort}"><i class="fileuploader-icon-sort"></i></button>' +
                        '<button type="button" class="fileuploader-action fileuploader-action-settings" title="${captions.edit}"><i class="fileuploader-icon-settings"></i></button>' +
                        '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                        '<div class="gallery-item-dropdown">' +
                        '<a href="${data.url}" target="_blank">${captions.setting_open}</a>' +
                        '<a href="${data.url}" download>${captions.setting_download}</a>' +
                        '<a class="fileuploader-action-popup">${captions.setting_edit}</a>' +
                        '<a class="gallery-action-rename">${captions.setting_rename}</a>' +
                        '<a class="gallery-action-asmain">${captions.setting_asMain}</a>' +
                        "</div>" +
                        "</div>" +
                        '<div class="thumbnail-holder">' +
                        "${image}" +
                        '<span class="fileuploader-action-popup"></span>' +
                        "</div>" +
                        '<div class="content-holder"><h5 title="${name}">${name}</h5><span>${size2}</span></div>' +
                        '<div class="type-holder">${icon}</div>' +
                        "</div>" +
                        "</li>",
                    itemPrepend: true,
                    startImageRenderer: true,
                    canvasImage: false,
                    onItemShow: function (
                        item,
                        listEl,
                        parentEl,
                        newInputEl,
                        inputEl
                    ) {
                        var api = $.fileuploader.getInstance(inputEl),
                            color = api.assets.textToColor(item.format),
                            $plusInput = listEl.find(".fileuploader-input"),
                            $progressBar = item.html.find(".progress-holder");

                        // put input first in the list
                        $plusInput.prependTo(listEl);

                        // color the icon and the progressbar with the format color
                        item.html
                            .find(".type-holder .fileuploader-item-icon")
                            [
                                api.assets.isBrightColor(color)
                                    ? "addClass"
                                    : "removeClass"
                            ]("is-bright-color")
                            .css("backgroundColor", color);
                    },
                    onItemRemove: function (html) {
                        html.fadeOut(250);
                    },
                },
                dragDrop: {
                    container:
                        ".fileuploader-theme-gallery .fileuploader-input",
                },
                upload: {
                    url: axios.defaults.baseURL + "files/save",
                    data: this.props.data,
                    type: "POST",
                    enctype: "multipart/form-data",
                    start: false,
                    synchron: true,
                    beforeSend: function (
                        item,
                        listEl,
                        parentEl,
                        newInputEl,
                        inputEl
                    ) {
                        item.upload.headers = {
                            Authorization: "Bearer " + token,
                            "Access-Control-Allow-Origin": "*",
                        };
                        item.upload.data = {
                            ...item.upload.data,
                            ...adicional,
                        };
                        item.html
                            .find(".fileuploader-action-success")
                            .removeClass("fileuploader-action-success");
                        var api = $.fileuploader.getInstance(inputEl);
                        totalFiles = api.getFiles();
                    },
                    onSuccess: function (result, item) {
                        var imgErrors = [];
                        Object.values(result).forEach((e) => {
                            imgErrors = imgErrors.concat(e);
                        });
                        // if warnings
                        if (imgErrors.length > 0) {
                            item.html
                                .removeClass("upload-successful")
                                .addClass("upload-failed");
                            item.upload.status = "error";
                            return this.onError ? this.onError(item) : null;
                        }

                        delete item.imU;
                        item.html
                            .find(".fileuploader-action-remove")
                            .addClass("fileuploader-action-success");

                        setTimeout(function () {
                            item.remove();
                            item.html.find(".progress-holder").hide();

                            item.html
                                .find(
                                    ".fileuploader-action-popup, .fileuploader-item-image"
                                )
                                .show();
                            nFiles++;
                            if (nFiles >= totalFiles) {
                                submitFunction();
                            }
                        }, 1400);
                    },
                    onError: function (
                        item,
                        listEl,
                        parentEl,
                        newInputEl,
                        inputEl,
                        jqXHR,
                        textStatus,
                        errorThrown
                    ) {
                        item.html
                            .find(
                                ".progress-holder, .fileuploader-action-popup, .fileuploader-item-image"
                            )
                            .hide();

                        if (
                            item.upload.status != "cancelled" &&
                            !item.imU &&
                            !item.html.find(".fileuploader-action-retry").length
                        ) {
                            item.html
                                .find(".actions-holder")
                                .prepend(
                                    '<button type="button" class="fileuploader-action fileuploader-action-retry" title="Retry"><i class="fileuploader-icon-retry"></i></button>'
                                );
                        } else {
                            return false;
                        }
                        onErrorFunction();
                    },
                    onProgress: function (data, item) {
                        var $progressBar = item.html.find(".progress-holder");

                        if ($progressBar.length) {
                            $progressBar.show();
                            $progressBar
                                .find("span")
                                .text(
                                    data.percentage >= 99
                                        ? "Uploading..."
                                        : data.percentage + "%"
                                );
                            $progressBar
                                .find(".fileuploader-progressbar .bar")
                                .height(data.percentage + "%");
                        }

                        item.html
                            .find(
                                ".fileuploader-action-popup, .fileuploader-item-image"
                            )
                            .hide();
                    },
                },

                beforeSelect: function (
                    files,
                    listEl,
                    parentEl,
                    newInputEl,
                    inputEl
                ) {
                    var api = $.fileuploader.getInstance(inputEl);
                    api.getFileList().forEach((e) => e.remove());

                    return true;
                },
                afterRender: function (listEl, parentEl, newInputEl, inputEl) {
                    var api = $.fileuploader.getInstance(inputEl),
                        $plusInput = listEl.find(".fileuploader-input");

                    // bind input click
                    $plusInput.on("click", function () {
                        api.open();
                    });
                },
                captions: {
                    feedback: "Drag & Drop",
                    setting_asMain: "Use as main",
                    setting_download: "Download",
                    setting_edit: "Edit",
                    setting_open: "Open",
                    setting_rename: "Rename",
                    rename: "Enter the new file name:",
                    renameError: "Please enter another name.",
                    imageSizeError: "The image ${name} is too small.",
                    ...captions,
                },
            })
        );
        this.api = $.fileuploader.getInstance(this.$el);
        const api = this.api;
    }

    componentWillUnmount() {
        if (this.api) this.api.destroy();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps != this.props) {
            if (this.state.send !== this.props.send) {
                this.uploadFiles();
            }
        }
    }

    uploadFiles = () => {
        const files = this.api.getFiles();
        this.setState(
            {
                send: !this.state.send,
                total: files,
            },
            () => {
                if (files.length < 1) {
                    this.state.onError();
                    return false;
                }
                adicional = { ...this.props.data };
                const options = this.api.getOptions();
                Promise.resolve(
                    this.api.setOption("upload", {
                        ...options.upload,
                        data: this.props.data,
                    })
                ).then((e) => {
                    this.api.updateFileList();
                    this.api.uploadStart();
                });
            }
        );
    };

    render() {
        if (typeof $.fn.fileuploader !== "function") {
            return false;
        }

        return (
            <>
                <input
                    type="file"
                    name={this.state.name}
                    ref={(el) => (this.el = el)}
                    className="gallery_media"
                />
            </>
        );
    }
}

export default Fileuploader;
