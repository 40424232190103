import React, { useEffect, useState } from "react";
import "../css/videocollection.css";
import Modal from "./Modal";
import Fileuploader from "../Fileuploader/Fileuploader";
import axios from "axios";

export default function VideoCollection(props) {
    const [files, setFiles] = useState(props.files);

    const [thumbnails, setThumbnails] = useState({});

    const handleClick = (e) => {
        const video = document.querySelector(".modal-content video");
        const actualSource = document.querySelector("video source");
        const source = document.createElement("source");
        source.src = e.src;
        source.type = e.type;

        if (actualSource) {
            video.removeChild(actualSource);
        }

        video.appendChild(source);
        video.load();
    };

    const fetchThumbnails = async () => {
        let thumbs = {};

        for (let i = 0; i < props.files.length; i++) {
            thumbs = {
                ...thumbs,
                [props.files[i].id]:
                    "data:image/png;base64," +
                    (await singleThumbnail(
                        props.files[i].path + props.files[i].nombre
                    )),
            };
        }

        setThumbnails(thumbs);
    };

    const singleThumbnail = async (path) => {
        const response = await axios.get("/files/thumbnail", {
            params: {
                path: path,
                size: 3,
            },
        });

        return response.data;
    };

    useEffect(() => {
        setFiles(props.files);
        fetchThumbnails();
    }, [props.files]);

    return (
        <>
            <h4>
                <i className="material-icons">video_library</i>
                &nbsp;Grabaciones
            </h4>
            <Modal
                id="video-collection-modal"
                options={{
                    onCloseEnd: () => {
                        document.querySelector("video").pause();
                    },
                }}
            >
                <div className="modal-content">
                    <video controls></video>
                </div>
            </Modal>
            <div className="video-list">
                {files.map((file) => (
                    <div
                        className="video-list-item"
                        key={file.id}
                        onClick={() =>
                            handleClick({
                                src: file.url,
                                type: file.mime,
                            })
                        }
                    >
                        <img
                            src={
                                thumbnails[file.id] ??
                                "https://tru-vue.com/wp-content/uploads/2015/11/video-icon.jpg"
                            }
                        />
                        <i
                            onClick={() => props.delete(file.id)}
                            className="material-icons"
                            style={{
                                position: "absolute",
                                cursor: "default",
                            }}
                        >
                            cancel
                        </i>
                        <div
                            className="video-list-action modal-trigger"
                            data-target="video-collection-modal"
                        >
                            <i className="material-icons">
                                play_circle_outline
                            </i>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
