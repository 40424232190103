import React, { useEffect, useState } from "react";
import Fileuploader from "../Fileuploader/Fileuploader";
import "../css/chocolat.css";
import Chocolat from "chocolat";

export default function ImageCollection(props) {
    const [images, setImages] = useState(props.images);

    useEffect(() => {
        setImages(props.images);
    }, [props.images]);

    useEffect(() => {
        setTimeout(() => {
            Chocolat(document.querySelectorAll(".chocolat-image"), {
                description: function () {
                    const currentImage =
                        this.images[this.settings.currentImageIndex];
                    return currentImage.title;
                },
            });
        }, 500);
    }, [images]);

    return (
        <>
            <h4>
                <i className="material-icons">collections</i>
                Imagenes
            </h4>
            <div className="chocolat-parent">
                {images?.map((image) => {
                    return (
                        <div
                            className="image-collection-item"
                            style={{ width: props.width ?? "200px" }}
                            key={image.id}
                        >
                            <i
                                onClick={(e) => props.delete(image.id)}
                                className="material-icons"
                                style={{
                                    position: "absolute",
                                    cursor: "default",
                                }}
                            >
                                cancel
                            </i>
                            <a
                                className="chocolat-image"
                                href={image.url ?? ""}
                                title={image.tags
                                    .map((e) => e.nombre)
                                    .join("; ")}
                            >
                                <img
                                    style={{ width: "100%" }}
                                    src={image.url}
                                    alt={image.nombre}
                                />
                            </a>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
