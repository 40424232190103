import React from "react";

export default function Icon({
    icon,
    onMouseDown = () => false,
    onClick = () => false,
    className = "",
    style = {},
}) {
    return icon.split(" ").length > 1 ? (
        <i
            className={icon + " " + className}
            onClick={onClick}
            onMouseDown={onMouseDown}
            style={style}
        ></i>
    ) : (
        <i
            className={"material-icons " + className}
            onClick={onClick}
            onMouseDown={onMouseDown}
            style={style}
        >
            {icon}
        </i>
    );
}
