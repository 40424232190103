import React, { useEffect, useState, useContext } from "react";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { TextField } from "@material-ui/core";
import MobileTimePicker from "@material-ui/lab/MobileTimePicker/MobileTimePicker";
import GroupContext from "./GroupContext";
import moment from "moment";

export default function TimepickerMaterial({ name, props }) {
    const [value, setValue] = useState(moment(props.value));

    const { onChangeForm } = useContext(GroupContext);

    const handleChange = (val) => {
        if (!val || !val.isValid()) {
            setValue({ ...value });
            return false;
        }

        if (onChangeForm) {
            props.value = val.format("HH:mm");
            onChangeForm();
        }
        setValue(val);
    };

    useEffect(() => {
        const idInterval = setInterval(() => {
            const input = document.querySelectorAll(".MuiInput-input");

            if (input) {
                document
                    .querySelectorAll(".MuiFormControl-root")
                    .forEach((e) =>
                        e.setAttribute("style", "width:100%;position:unset")
                    );
                input.forEach((e) => e.classList.add("browser-default"));
                clearInterval(idInterval);
            }
        }, 100);
        handleChange(value);
    }, []);

    return (
        <>
            <div
                className={
                    "row form-datepicker-material form-" +
                    props.id?.toLowerCase()
                }
                key={props.id ?? 1}
            >
                <div className="input-field col s12">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileTimePicker
                            label={props.label ?? name}
                            ampm={false}
                            value={value}
                            {...(props.instance ?? {})}
                            onChange={handleChange}
                            minTime={
                                props.instance?.minTime
                                    ? moment(
                                          props.instance?.minTime,
                                          moment.HTML5_FMT.TIME
                                      )
                                          .set("seconds", 0)
                                          .toDate()
                                    : null
                            }
                            maxTime={
                                props.instance?.maxTime
                                    ? moment(
                                          props.instance?.maxTime,
                                          moment.HTML5_FMT.TIME
                                      )
                                          .set("seconds", 59)
                                          .toDate()
                                    : null
                            }
                            renderInput={(params) => (
                                <TextField
                                    value={value}
                                    className={{
                                        text: {
                                            width: "100%",
                                        },
                                    }}
                                    variant="standard"
                                    {...params}
                                    helperText=""
                                    placeholder="HH:mm"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </>
    );
}
