import React, { useEffect, useState } from "react";
import FloatButton from "../FloatButton";
import DocumentosForm from "../forms/DocumentosForm";
import Modal from "../Modal";

export default function HeaderDocuments(props) {
    const [modal, setModal] = useState(undefined);

    useEffect(() => {
        setModal(props.current);
    }, [props.current]);
    return (
        <>
            <FloatButton>
                <li>
                    <button
                        className="btn-floating green modal-trigger"
                        data-target="documentosForm"
                        onClick={() => {
                            props.setCurrent(undefined);
                            setModal(true);
                        }}
                    >
                        <i className="material-icons">add_circle_outline</i>
                    </button>
                </li>
            </FloatButton>
            <Modal
                id="documentosForm"
                options={{
                    onCloseEnd: () => {
                        props.setCurrent(undefined);
                        props.fetchData();
                        setModal(undefined);
                    },
                }}
            >
                <div className="modal-content">
                    {modal && <DocumentosForm currentDoc={props.current} />}
                </div>

                <div className="modal-footer">
                    <button
                        href="#!"
                        className="modal-close waves-effect waves-green btn-flat"
                    >
                        Cancelar
                    </button>
                </div>
            </Modal>
        </>
    );
}
