import React, { useContext, useState } from "react";
import GroupContext from "./GroupContext";
import { Inputs } from "./Inputs";

export default function Group({
    name,
    props,
    onChange = (e) => {
        console.log(e);
    },
}) {
    const [state, setState] = useState({
        n: 0,
    });

    let contador = 0;

    const initialElements = Object.keys(props.childs);

    const getTemplate = () => {
        const key = Object.keys(props.template)[0];
        const val = Object.values(props.template)[0];
        let name = key + " " + state.n;
        const value = { ...val };
        value.id = val.id + "-" + state.n;
        setState({ n: state.n + 1 });
        return { [name]: value };
    };

    const { modifyGroup } = useContext(GroupContext);

    const addChild = () => {
        const newChild = getTemplate();
        const childs = {
            ...props.childs,
            ...newChild,
        };

        const group = {
            [name]: {
                ...props,
                childs: childs,
            },
        };
        modifyGroup(group);
    };

    const rmChild = () => {
        if (Object.keys(props.childs).length >= initialElements) {
            return false;
        }
        const lastChild = Object.keys(props.childs)[
            Object.keys(props.childs).length - 1
        ];
        const group = {
            [name]: {
                ...props,
            },
        };
        delete group[name]["childs"][lastChild];

        modifyGroup(group);
    };

    const handleChange = (e) => {};

    return (
        <React.Fragment>
            {props.template && (
                <div className="row">
                    <div className="col s12">
                        <button
                            type="button"
                            className="btn red white-text right"
                            onClick={rmChild}
                        >
                            <i className="material-icons">remove</i>
                        </button>
                        <button
                            type="button"
                            className="btn green white-text right"
                            onClick={addChild}
                        >
                            <i className="material-icons">add</i>
                        </button>
                    </div>
                </div>
            )}
            <div className={"row form-group " + (props.cssClass ?? "")}>
                {props.show && <p className="group-title">{name}</p>}
                {Object.entries(props.childs ?? {}).map(([key, val]) => {
                    val.group = name;
                    contador++;
                    return (
                        <div
                            className={"col s12 " + props.cols ?? ""}
                            key={contador}
                        >
                            {Inputs[val.type](key, val, handleChange)}
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
}
