import React, { useEffect, useState } from "react";
import M from "materialize-css";

export default function Chips({
    id,
    options = {},
    onChange = (e) => {},
    onInit = (e) => {},
}) {
    const [instance, setInstance] = useState(undefined);

    useEffect(() => {
        var element = document.querySelector("#" + id);
        var instances = M.Chips.init(element, {
            ...options,
            onChipAdd: (e) => {
                var tags = e[0].M_Chips.chipsData.map((e) => e.tag);
                onChange(tags);
            },
            onChipDelete: (e) => {
                var tags = e[0].M_Chips.chipsData.map((e) => e.tag);
                onChange(tags);
            },
        });
        setInstance(instances);
        onInit(instances);
    }, [options]);

    return (
        <div className="chips" id={id}>
            <input />
        </div>
    );
}
