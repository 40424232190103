import axios from "axios";
import React from "react";
import "../css/generalForms.css";
import M from "materialize-css";

class LoginForm extends React.Component {
    constructor() {
        super();
        this.state = {
            input: {
                login: "",
                password: "",
            },
            error: undefined,
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/login", {
                login: this.state.input.login,
                password: this.state.input.password,
            });

            this.props.auth(JSON.stringify(response.data));
        } catch (error) {
            M.toast({ html: "Error al Logear" });
        }
    };

    handleChange = (e) => {
        this.setState({
            input: {
                ...this.state.input,
                [e.target.id]: e.target.value,
            },
        });
    };

    render() {
        return (
            <div className="row" style={{ padding: "100px" }}>
                <form className="col s12" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="input-field col s12">
                            <i className="material-icons prefix">
                                account_circle
                            </i>
                            <input
                                id="login"
                                type="text"
                                className="validate"
                                onChange={this.handleChange}
                                value={this.state.input.login}
                            />
                            <label htmlFor="login">Usuario</label>
                        </div>
                        <div className="input-field col s12">
                            <i className="material-icons prefix">vpn_key</i>
                            <input
                                id="password"
                                type="password"
                                className="validate"
                                onChange={this.handleChange}
                                value={this.state.input.password}
                            />
                            <label htmlFor="password">Contraseña</label>
                        </div>
                        <div className="input-field col s12">
                            <button
                                type="submit"
                                className="btn btn-primary btn-block bt"
                            >
                                <i className="material-icons left">lock</i>
                                Comenzar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default LoginForm;
