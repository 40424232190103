import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import DataTable from "../DataTable";
import DocumentosForm from "../forms/DocumentosForm";
import { downloadBlob } from "../../helpers";
import axios from "axios";
import ReactDOM from "react-dom";
import M from "materialize-css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";

export default function ActividadDocuments({
    documentos,
    fetchData = () => {},
}) {
    const [actual, setActual] = useState(undefined);

    const [documents, setDocuments] = useState(undefined);

    const transformDocumentsData = () => {
        if (!documentos) {
            return false;
        }
        const documentsData = documentos.map((doc) => {
            return {
                ref: doc.id_documento,
                nombre: doc.title,
                categoria: doc.categoria.nombre,
                descripcion: doc.descripcion,
                tipo: doc.dtype.nombre,
                espacio: doc.espacio?.nombre ?? "",
                fecha: doc.created_at,
                size: doc.size,
                format: doc.file_type?.nombre ?? "",
            };
        });
        setDocuments(documentsData);
    };
    const downloadDoc = async (id) => {
        const response = await axios.get("/document/download/" + id, {
            responseType: "blob",
        });
        downloadBlob(response);
    };

    const deleteDoc = async (id) => {
        const MySwal = withReactContent(Swal);
        const confirmed = await MySwal.fire({
            title: <p>Eliminar documento ?</p>,
            showDenyButton: true,
            showCloseButton: true,
        });

        if (!confirmed.isConfirmed) {
            return false;
        }

        const response = await axios.post("/document/delete", {
            id: id,
        });

        if (response.data === true) {
            setDocuments([documents.filter((e) => e.id_documento != id)]);

            M.toast({
                html: "Eliminación completa",
                classes: "red white-text",
            });
            fetchData();
        }
    };

    useEffect(() => {
        transformDocumentsData();
    }, [documentos]);

    if (!documents) {
        return <p>Loading</p>;
    }

    const portal = document.getElementById("portal");

    return (
        <>
            <div className="row">
                {ReactDOM.createPortal(
                    <Modal
                        id="documentModal"
                        options={{
                            onCloseEnd: () => {
                                setActual(undefined);
                            },
                        }}
                    >
                        <div className="modal-content">
                            <h4>Documento</h4>
                            {actual && <DocumentosForm currentDoc={actual} />}
                        </div>
                        <div className="modal-footer">
                            <button className="modal-close waves-effect waves-green btn-flat">
                                Cancelar
                            </button>
                        </div>
                    </Modal>,
                    portal
                )}
            </div>
            {documents && (
                <DataTable
                    id="document"
                    data={documents}
                    columns={[
                        {
                            Header: "ref",
                            accessor: "ref",
                            Cell: (props) => "DOC" + props.row.values.ref,
                        },
                        {
                            Header: "Nombre",
                            accessor: "title",
                            Cell: (props) => {
                                return (
                                    <span
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            downloadDoc(props.row.original.ref)
                                        }
                                    >
                                        {props.row.original.nombre}
                                    </span>
                                );
                            },
                        },
                        {
                            Header: "Categoria",
                            accessor: "categoria",
                        },
                        {
                            Header: "Descripción",
                            accessor: "descripcion",
                        },
                        {
                            Header: "Espacio",
                            accessor: "espacio",
                        },
                        {
                            Header: "Tipo Doc",
                            accessor: "tipo",
                        },
                        {
                            Header: "Fecha",
                            accessor: "fecha",
                            Cell: (props) => {
                                const value = props.row.values.fecha;
                                return moment(new Date(value)).format(
                                    "DD/MM/YYYY"
                                );
                            },
                        },
                        {
                            Header: "Tamaño",
                            accessor: "size",
                        },
                        {
                            Header: "Formato",
                            accessor: "format",
                        },
                        {
                            Header: "Accion",
                            Cell: (props) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <i
                                            style={{ cursor: "pointer" }}
                                            className="material-icons green-text"
                                            onClick={() =>
                                                downloadDoc(
                                                    props.row.original.ref
                                                )
                                            }
                                        >
                                            download
                                        </i>
                                        <i
                                            style={{ cursor: "pointer" }}
                                            className="material-icons green-text modal-trigger"
                                            data-target="documentModal"
                                            onClick={() =>
                                                setActual(
                                                    props.row.original.ref
                                                )
                                            }
                                        >
                                            edit_note
                                        </i>
                                        <i
                                            className="material-icons red-text"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                deleteDoc(
                                                    props.row.original.ref
                                                )
                                            }
                                        >
                                            delete
                                        </i>
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            )}
        </>
    );
}
