import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "../../Formbuilder/Form";
import M from "materialize-css";

export default function DocumentosForm(props) {
    const [data, setData] = useState(undefined);

    const fetchData = async () => {
        const response = await axios.post("/form/build", {
            form: "Documentos",
            action: "create",
            id: props.currentDoc ?? null,
        });

        if (response.status === 200) {
            if (props.currentActivity) {
                response.data.id_actividad.value = [props.currentActivity];
                response.data.id_actividad.disabled = true;
            }
            setData(response.data);
        }
    };

    const handleChange = (e) => {
        setData(e);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let button = document.querySelector('[type="submit"]');
        button.setAttribute("disabled", true);
        const uri = props.currentDoc ? "edit" : "create";
        try {
            const response = await axios.post("/document/" + uri, {
                ...data,
            });

            if (response.data !== false) {
                let files = new FormData();
                let input = document.getElementById("file");
                files.append("file", input.files[0]);
                files.append("info", JSON.stringify(response.data));
                const sendFile = await axios.post(
                    "/document/file/save",
                    files,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                const msg = props.currentDoc ? "Edición" : "Creación";
                M.toast({ html: msg + " completada", classes: "green" });
            }
        } catch (error) {
            M.toast({ html: "Error", classes: "red" });
            console.log(error, error.sendFile);
        }
        button.removeAttribute("disabled");
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!data) {
        return <p>Loading ...</p>;
    }
    return <Form data={data} onChange={handleChange} onSubmit={handleSubmit} />;
}
