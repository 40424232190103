import React from "react";
import Login from "../vistas/Login";
import Navbar from "./Navbar";
import "../css/layout.css";
import Footer from "./Footer";
import { NavbarProvider } from "../contextos/NavbarContext";

class Layout extends React.Component {
    state = {
        user: undefined,
        navbarName: undefined,
    };

    componentDidMount() {
        const userDataFromLocal = localStorage.getItem("user");

        if (userDataFromLocal !== null) {
            this.setState({
                ...this.state,
                user: userDataFromLocal,
            });
        }
    }

    render() {
        if (!this.state.user) {
            return <Login />;
        }
        return (
            <React.Fragment>
                <NavbarProvider
                    value={{
                        setName: (value) => {
                            this.setState({
                                navbarName: value,
                            });
                        },
                        user: JSON.parse(this.state.user),
                    }}
                >
                    <Navbar
                        menu={this.props.menu}
                        name={this.state.navbarName}
                    />
                    <div id="ReactappBody">{this.props.children}</div>
                    <Footer />
                </NavbarProvider>
            </React.Fragment>
        );
    }
}

export default Layout;
