import React, { useEffect } from "react";
import { TimepickerInstance } from "./instances";

export default function Timepicker({ name, props, handler = undefined }) {
    const prop = {
        id: "timeID_" + props.id ?? name,
        className: (props.className ? props.className : "") + " timepicker ",
        placeholder: props.placeholder,
        value: props.value ?? "",
    };

    if (props.required) {
        prop.required = true;
    }

    if (handler) {
        prop.onChange = handler;
    }

    if (props.group) {
        prop["data-group"] = props.group;
    }

    useEffect(() => {
        TimepickerInstance("#" + prop.id, {
            twelveHour: false,
        });
    }, []);

    return (
        <div className="row" key={prop.id}>
            <div className="input-field col s12">
                {props.icon &&
                    (props.icon.custom ? (
                        <i className={props.icon.class}></i>
                    ) : (
                        <i
                            className="material-icons prefix"
                            style={{ color: props.iconColor }}
                        >
                            {props.icon}
                        </i>
                    ))}
                <input
                    type="text"
                    {...prop}
                    onChange={(e) => {
                        props.value = e.target.value;
                    }}
                />
                <label htmlFor={prop.id} className={prop.value && "active"}>
                    {props.label ?? name}
                </label>
            </div>
        </div>
    );
}
