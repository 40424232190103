import React, { useState } from "react";
import moment from "moment";
import Icon from "../Icon";

export default function ActividadDataInit(props) {
    const [data, setData] = useState(props.data);

    return (
        <>
            <div className="row">
                <div className="col s2">
                    <i className="material-icons left">date_range</i>
                </div>
                <div className="col s10">
                    {moment(new Date(data.fecha_inicio)).format(
                        "ddd DD/MMM YYYY"
                    )}
                    {data.fecha_fin > data.fecha_inicio
                        ? " - " +
                          moment(new Date(data.fecha_fin)).format(
                              "ddd DD/MMM YYYY"
                          )
                        : ""}
                </div>
            </div>
            <div className="row">
                <div className="col s2 l1">
                    <i className="material-icons left">query_builder</i>
                </div>
                <div className="col s4 l5">
                    {data.hora_inicio + " / "}
                    {data.hora_fin > data.hora_inicio ? data.hora_fin : ""}
                </div>
                <div className="col s2 l1">
                    <i className="material-icons left">groups</i>
                </div>
                <div className="col s4 l5">{data.hora_evento}</div>
            </div>
            <div className="row">
                <div className="col s2 l1">
                    <i className="material-icons left">hourglass_empty</i>
                </div>
                <div className="col s4 l5">{data.duracion}</div>
                <div className="col s2 l1">
                    <Icon icon={data.acceso.icon} className="left" />
                </div>
                <div className="col s4 l5">{data.acceso.nombre}</div>
            </div>
            <div className="row">
                <div className="col s2 l1">
                    <i className="material-icons">place</i>
                </div>
                <div className="col s10 l11">
                    {data.espacios.map((e) => e.nombre + " / ")}
                </div>
            </div>
            <div className="row">
                <div className="col s2 l1">
                    <i className="material-icons">manage_accounts</i>
                </div>
                <div className="col s10 l11">{data.empresa?.nombre ?? ""}</div>
                <div className="col s12">
                    <hr></hr>
                </div>
            </div>
        </>
    );
}
