import moment from "moment";
import React, { useEffect, useState } from "react";

export default function FileCollection(props) {
    const [files, setFiles] = useState(props.files);

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    return (
        <>
            <h4>
                <i className="material-icons">{props.icon}</i> {props.title}
            </h4>
            <ul className="files-list collection">
                {files.map((e) => {
                    return (
                        <li className="collection-item avatar" key={e.id}>
                            <i className="material-icons circle green">
                                file_present
                            </i>
                            <div
                                className="row"
                                style={{ marginBottom: "unset" }}
                            >
                                <div className="col s12 l6">
                                    <a
                                        href={e.url.substring(
                                            0,
                                            e.url.length - 6
                                        )}
                                        target="_blank"
                                        className="title"
                                    >
                                        {e.nombre}
                                    </a>
                                    <p>
                                        <b>Fecha subida : </b>
                                        {moment(new Date(e.created_at)).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </p>
                                    <p>
                                        <b>Usuario : </b>
                                        {e.user.nombre}
                                    </p>
                                </div>
                                <div className="col s12 l6"></div>
                            </div>
                            <div className="secondary-content">
                                <a href={e.url.split("?")[0] + "?dl=1"}>
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: "36px" }}
                                    >
                                        file_download
                                    </i>
                                </a>
                                <a
                                    href="#!"
                                    className="red-text"
                                    onClick={() => props.delete(e.id)}
                                >
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: "36px" }}
                                    >
                                        delete
                                    </i>
                                </a>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
