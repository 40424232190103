import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ImageCollection from "../ImageCollection";
import VideoCollection from "../VideoCollection";
import ActividadDocuments from "./ActividadDocuments";
import { TableDetalles } from "./ActividadInfo";
import Fileuploader from "../../Fileuploader/Fileuploader";
import ActividadDataInit from "./ActividadDataInit";
import NavbarContext from "../../contextos/NavbarContext";
import FileCollection from "../FileCollection";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import M from "materialize-css";
import Chips from "../Chips";
import { useStateCallback } from "../../helpers";
import Modal from "../Modal";
import DocumentosForm from "../forms/DocumentosForm";

export default function Actividad(props) {
    const [id, setId] = useState(props.match.params.id);

    const [actividad, setActividad] = useState(undefined);

    const [send, setSend] = useState(false);

    const [autocomplete, setAutocomplete] = useState(undefined);

    const [documentForm, setDocumentForm] = useState(undefined);

    const [fileUploaderData, setFileuploaderData] = useStateCallback({
        entity: "Actividad",
        id_entity: id,
    });

    const chips = useRef(undefined);

    const { setName } = useContext(NavbarContext);

    const fetchData = async () => {
        const response = await axios.get("/actividad/" + id + "/complete");

        setActividad(response.data);

        setName(response.data.nombre);
    };

    const fetchSharedUrl = async () => {
        try {
            const response = await axios.post("/files/sharedurl", {
                id_actividad: id,
            });

            setActividad({
                ...actividad,
                shared_url: response.data,
            });
        } catch (error) {}
    };

    const fetchTags = async () => {
        try {
            const response = await axios.get("/tags/list");

            setAutocomplete(response.data);
        } catch (error) {}
    };

    const uploadFiles = async (e) => {
        document
            .querySelector(".fileuploader-button")
            .setAttribute("disabled", true);
        setFileuploaderData(
            {
                ...fileUploaderData,
                tags: chips.current.chipsData.map((e) => e.tag),
            },
            (state) => {
                setTimeout(() => {
                    setSend(!send);
                }, 500);
            }
        );
    };

    const deleteFile = async (id) => {
        const MySwal = withReactContent(Swal);

        const confirmed = await MySwal.fire({
            title: <p>Eliminar archivo de actividad ?</p>,
            showDenyButton: true,
            showCloseButton: true,
        });

        if (confirmed.isConfirmed) {
            try {
                const response = await axios.post("/files/delete", {
                    id: id,
                });

                if (response.data === true) {
                    M.toast({
                        html: "Emilinación Completa",
                        classes: "green white-text",
                    });
                } else {
                    M.toast({
                        html: "Error al emilinar",
                        classes: "red white-text",
                    });
                }
            } catch (error) {
                M.toast({
                    html: "Error al emilinar",
                    classes: "red white-text",
                });
            }
        }

        fetchData();
    };

    const handleSubmitFiles = () => {
        document
            .querySelector(".fileuploader-button")
            .removeAttribute("disabled");

        fetchData();
    };

    useEffect(() => {
        fetchData();
        fetchTags();
        return () => {
            setName(undefined);
        };
    }, []);

    useEffect(() => {
        if (!actividad) {
            return false;
        }
        if (actividad.shared_url === "") {
            fetchSharedUrl();
        }
    }, [actividad]);

    if (!actividad) {
        return "Loading ...";
    }

    moment.locale("es");

    const detalles = {
        Ref: actividad.id,
        ["Responsable CASA SEAT"]: actividad.responsable?.nombre ?? "",
        Modalidad: actividad.modalidad?.nombre ?? "",
        Estado: actividad.estados?.nombre ?? "",
        Acceso: actividad.acceso?.nombre ?? "",
        Nivel: actividad.top?.nombre ?? "",
        Patrocinio: actividad.patrocinio?.nombre ?? "",
        ["Publicacion web"]: actividad.web == 1 ? "Sí" : "No",
        Espacio: actividad.espacios.map((e) => e.nombre).join(", "),
        Tematica: actividad.tematica?.nombre ?? "",
        ["Tipo de actividad"]: actividad.tipo?.nombre ?? "",
        ["Eje tematico"]: actividad.eje?.nombre,
        ["Tipo de publico"]: actividad.tipo?.nombre,
        Aforo: actividad.aforo,
        Responsable: actividad.empresa?.nombre ?? "",
        ["Coste CASA SEAT"]: actividad.coste_interno,
        ["Coste agencia"]: actividad.coste_agencia,
        ["Coste terceros"]: actividad.coste_externo,
        ["Coste terceros (Otros)"]: actividad.coste_externo_txt,
    };

    return (
        <>
            <div className="container">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <p style={{ fontWeight: "bold" }}>REF: {id}</p>
                                <h4>
                                    <b>{actividad.nombre}</b>
                                </h4>
                            </div>
                            <div
                                className="col s12 l6"
                                style={{
                                    maxHeight: "400px",
                                    overflowY: "scroll",
                                }}
                            >
                                <EstadosCollection
                                    estados={actividad.estados}
                                />
                            </div>
                            <div className="col s12 l6">
                                <ActividadDataInit data={actividad} />
                            </div>
                        </div>
                        <div className="row">
                            {actividad.descripcion && (
                                <div className="col s12">
                                    <h4>
                                        <i className="material-icons">
                                            format_align_left
                                        </i>{" "}
                                        Descripción
                                    </h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: actividad.descripcion,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {actividad.contraprestaciones && (
                                <div className="col s12">
                                    <h4>
                                        <i className="material-icons">
                                            receipt
                                        </i>{" "}
                                        Contraprestaciones
                                    </h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: actividad.contraprestaciones,
                                        }}
                                    ></div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div
                                className="col s12 light-blue lighten-4 center-align"
                                style={{ padding: "10px" }}
                            >
                                <Fileuploader
                                    limit="20"
                                    maxSize="200"
                                    name="files"
                                    data={fileUploaderData}
                                    send={send}
                                    extensions="image/*"
                                    captions={{ feedback: "Cargar Imagenes" }}
                                    onSubmit={handleSubmitFiles}
                                    onError={handleSubmitFiles}
                                />
                                <Chips
                                    options={{
                                        autocompleteOptions: {
                                            data: autocomplete,
                                            onAutocomplete: (e) => {
                                                console.log(e);
                                            },
                                        },
                                        placeholder: "Tags de imagen",
                                        secondaryPlaceholder: "+Tag",
                                    }}
                                    onInit={(instance) =>
                                        (chips.current = instance)
                                    }
                                    id="fileUploaderTags"
                                />

                                <button
                                    className="btn fileuploader-button"
                                    onClick={uploadFiles}
                                >
                                    <i className="material-icons">
                                        collections
                                    </i>{" "}
                                    Subir Imagenes
                                </button>
                            </div>
                            <div
                                className="col s12 red lighten-4 center-align"
                                style={{ padding: "10px" }}
                            >
                                {actividad.shared_url !== "" && (
                                    <a
                                        href={actividad.shared_url}
                                        target="_blank"
                                        className="btn"
                                    >
                                        <i className="material-icons">
                                            video_library
                                        </i>{" "}
                                        Subir grabación
                                    </a>
                                )}
                            </div>
                            {actividad.filesCategories.map((e) => {
                                if (!e.filesObject) {
                                    return;
                                }
                                switch (e.nombre) {
                                    case "Imagen":
                                        return (
                                            <div className="col s12" key={e.id}>
                                                <ImageCollection
                                                    delete={deleteFile}
                                                    images={e.filesObject}
                                                />
                                            </div>
                                        );
                                    case "Video":
                                        return (
                                            <div className="col s12" key={e.id}>
                                                <VideoCollection
                                                    delete={deleteFile}
                                                    files={e.filesObject}
                                                />
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div className="col s12" key={e.id}>
                                                <FileCollection
                                                    delete={deleteFile}
                                                    icon={e.icon}
                                                    title={e.nombre}
                                                    files={e.filesObject}
                                                />
                                            </div>
                                        );
                                }
                            })}
                        </div>
                        <div className="row">
                            <Modal
                                id="actividadDocumentosForm"
                                options={{
                                    onCloseEnd: () => {
                                        fetchData();
                                        setDocumentForm(undefined);
                                    },
                                }}
                            >
                                <div className="modal-content">
                                    {documentForm && (
                                        <DocumentosForm currentActivity={id} />
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        href="#!"
                                        className="modal-close waves-effect waves-green btn-flat"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Modal>
                            <div className="col s12">
                                <hr></hr>
                                <h4>
                                    <i className="material-icons">folder</i>
                                    Documentos
                                    <button
                                        className="btn modal-trigger orange"
                                        style={{ marginLeft: "30px" }}
                                        data-target="actividadDocumentosForm"
                                        onClick={() => setDocumentForm(true)}
                                    >
                                        <i className="material-icons white-text">
                                            add_circle_outline
                                        </i>
                                    </button>
                                </h4>
                                {actividad.documentos.length > 0 ? (
                                    <ActividadDocuments
                                        fetchData={fetchData}
                                        documentos={actividad.documentos}
                                    />
                                ) : (
                                    <>No hay documentos asociados</>
                                )}
                            </div>
                            <div className="col s12"></div>
                        </div>
                        <div className="row">
                            <div className="col s12 l6">
                                <h4>
                                    <i className="material-icons">info</i>
                                    Detalles
                                </h4>
                                <TableDetalles detalles={detalles} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="portal"></div>
        </>
    );
}

function EstadosCollection({ estados }) {
    return (
        <ul className="collection with-header">
            <li className="collection-header" key="0">
                <h4>Registro de estados</h4>
            </li>
            {estados.map((estado) => {
                return (
                    <li
                        className={
                            "collection-item avatar " +
                            (estado.pivot.active === 1 ? "active" : "")
                        }
                        key={estado.id_estado}
                    >
                        <div>
                            <i
                                className={
                                    "material-icons circle " + estado.color
                                }
                            >
                                {estado.icon}
                            </i>
                            <span className="title">{estado.nombre}</span>
                            <p>
                                {moment(
                                    new Date(estado.pivot.created_at)
                                ).format(
                                    "[creado el] DD [de] MMM [del] YYYY"
                                )}{" "}
                                <br />
                                {"Creado por : " + estado.user.nombre}
                            </p>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
