import React from "react";
import LoginForm from "../componentes/LoginForm";

class Login extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col s12 l6">
          <LoginForm auth={this.props.auth} />
        </div>
      </div>
    );
  }
}

export default Login;
