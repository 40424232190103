import React, { useEffect } from "react";
import M from "materialize-css";

export default function FloatButton(props) {
    useEffect(() => {
        const instance = M.FloatingActionButton.init(
            document.querySelector("#actionButton"),
            {
                direction: props.direction ?? "right",
                hoverEnabled: false,
            }
        );
        instance.open();
    }, [props.direction]);

    return (
        <div style={{ display: "flex" }}>
            <div
                className="fixed-action-btn"
                id="actionButton"
                style={{
                    position: "relative",
                    bottom: "initial",
                    right: "initial",
                    marginTop: "22px",
                    marginLeft: "22px",
                }}
            >
                <button className="btn-floating btn-large orange">
                    <i className="large material-icons white-text">
                        {props.icon ?? "mode_edit"}
                    </i>
                </button>
                <ul>{props.children}</ul>
            </div>
        </div>
    );
}
