import React from "react";

export default function View(props) {
    return (
        <div
            id={props.id}
            key={props.id}
            dangerouslySetInnerHTML={{ __html: props.view }}
        ></div>
    );
}
