import React, { useEffect, useState } from "react";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    usePagination,
    useRowState,
    useColumnOrder,
} from "react-table";
import { useExportData } from "react-table-plugins";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    id,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    });

    return (
        <div className="col s12 l6 input-field">
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                id={"reactTableSearch" + (id ?? "")}
                className="search-react-table-input"
            />
            <label
                htmlFor={"reactTableSearch" + (id ?? "")}
                className={value && "active"}
            >
                Search: {`${count} records...`}
            </label>
        </div>
    );
}

const CONFIG_COLUMN = {
    filter: (rows, id, filterValue) => {
        if (filterValue == "") {
            return rows;
        }
        const filter = rows.filter((row) => {
            const rowValue = row.values[id];
            const result = filterValue.includes(String(rowValue));
            return result;
        });
        return filter;
    },
};

function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "xlsx") {
        // XLSX example

        const header = columns.map((c) => c.exportValue);
        const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
                obj[col] = row[index];
            });
            return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        // Returning false as downloading of file is already taken care of
        return false;
    }
    //PDF example
    if (fileType === "pdf") {
        const headerNames = columns.map((column) => column.exportValue);
        const doc = new JsPDF();
        doc.autoTable({
            head: [headerNames],
            body: data,
            margin: { top: 20 },
            styles: {
                minCellHeight: 9,
                halign: "left",
                valign: "center",
                fontSize: 11,
            },
        });
        doc.save(`${fileName}.pdf`);

        return false;
    }

    return false;
}

function DataTable({
    columns,
    data,
    id,
    functions = undefined,
    pagination = false,
    filterobj = undefined,
    sortBy = undefined,
    columnsOrder = undefined,
    paginationButtons = 5,
    withExport = false,
    exportName = "data",
}) {
    const paginationButtonsArray = Array.from(
        Array(parseInt(paginationButtons)).keys()
    );
    const paginationButtonsMiddle = Math.floor(paginationButtons / 2);

    function getExportFileName({ fileType, all }) {
        return exportName;
    }

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
            between: (rows, id, filterValue) => {
                if (filterValue[0] === null && filterValue[1] === null) {
                    return rows;
                }
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return (
                        rowValue >= filterValue[0] && rowValue <= filterValue[1]
                    );
                });
            },
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        pageCount,
        state: { pageIndex, pageSize = 20, filters },
        pageOptions,
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        setAllFilters,
        exportData,
        visibleColumns,
        setColumnOrder,
    } = useTable(
        {
            autoResetHiddenColumns: false,
            columns,
            data,
            defaultColumn: CONFIG_COLUMN,
            initialState: {
                sortBy: [
                    sortBy ?? {
                        id: "id",
                        desc: false,
                    },
                ],
                hiddenColumns: columns.map((column) => {
                    return column.isVisible === false && column.accessor;
                }),
            },
            id,
            filterTypes,
            functions,
            getExportFileBlob,
            getExportFileName,
        },
        useState,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowState,
        useColumnOrder,
        useExportData
    );

    useEffect(() => {
        if (!columnsOrder) {
            return;
        }
        setColumnOrder([
            ...columnsOrder,
            ...visibleColumns.map((e) => {
                if (columnsOrder.includes(id)) {
                    return;
                }
                return e.id;
            }),
        ]);
    }, [columnsOrder]);

    useEffect(() => {
        if (filterobj) {
            setAllFilters(filterobj);
        }
    }, [filterobj]);

    useEffect(() => {
        setPageSize(20);
    }, []);

    return (
        <div>
            <div className="row" style={{ marginBottom: "0" }}>
                <div className="col s12 l6">
                    {withExport && (
                        <>
                            <button
                                className="btn orange data-table-export-xlsx"
                                onClick={() => exportData("xlsx", false)}
                            >
                                export XlSX
                                <i className="far fa-file-excel right"></i>
                            </button>
                            <button
                                className="btn orange data-table-export-xlsx"
                                onClick={() => exportData("pdf", false)}
                            >
                                export PDF
                                <i className="far fa-file-excel right"></i>
                            </button>
                        </>
                    )}
                </div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    id={id}
                />
            </div>
            <table
                {...getTableProps()}
                id={id}
                className="striped responsive-table"
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        {...column.props}
                                        title=""
                                    >
                                        <label>{column.render("Header")}</label>
                                        <i className="material-icons right">
                                            {" "}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? "arrow_drop_up"
                                                    : "arrow_drop_down"
                                                : ""}
                                        </i>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(pagination ? page : rows).map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {pagination && pageOptions.length > 1 && (
                <div
                    className="row"
                    style={{ marginTop: "20px", marginBottom: "0" }}
                >
                    <ul className="pagination col s12 l6">
                        <li
                            className={!canPreviousPage ? "disabled" : ""}
                            key="-2"
                        >
                            <button
                                style={{ padding: "0" }}
                                type="button"
                                className="waves-effect btn-flat"
                                onClick={() => {
                                    gotoPage(0);
                                }}
                                disabled={!canPreviousPage}
                            >
                                <i
                                    className="material-icons"
                                    style={{ marginRight: "-20px" }}
                                >
                                    chevron_left
                                </i>
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        <li
                            className={!canPreviousPage ? "disabled" : ""}
                            key="-1"
                        >
                            <button
                                style={{ padding: "0" }}
                                type="button"
                                className="waves-effect btn-flat"
                                onClick={() => {
                                    previousPage();
                                }}
                                disabled={!canPreviousPage}
                            >
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        {paginationButtonsArray.map((e) => {
                            if (
                                typeof pageOptions[
                                    pageIndex -
                                        paginationButtonsMiddle +
                                        parseInt(e)
                                ] !== "undefined"
                            ) {
                                return (
                                    <li
                                        key={
                                            pageOptions[
                                                pageIndex -
                                                    paginationButtonsMiddle +
                                                    parseInt(e)
                                            ] + 1
                                        }
                                        className="pagination-buttons"
                                    >
                                        <button
                                            type="button"
                                            className={
                                                "waves-effect btn " +
                                                (pageIndex ===
                                                pageOptions[
                                                    pageIndex -
                                                        paginationButtonsMiddle +
                                                        parseInt(e)
                                                ]
                                                    ? "orange"
                                                    : "btn-flat")
                                            }
                                            data-keyarray={e}
                                            onClick={() => {
                                                gotoPage(
                                                    pageOptions[
                                                        pageIndex -
                                                            paginationButtonsMiddle +
                                                            parseInt(e)
                                                    ]
                                                );
                                            }}
                                        >
                                            {pageOptions[
                                                pageIndex -
                                                    paginationButtonsMiddle +
                                                    parseInt(e)
                                            ] + 1}
                                        </button>
                                    </li>
                                );
                            }
                        })}
                        <li className={!canNextPage ? "disabled" : ""} key="-3">
                            <button
                                style={{ padding: "0" }}
                                type="button"
                                className="waves-effect btn-flat"
                                onClick={() => {
                                    nextPage();
                                }}
                                disabled={!canNextPage}
                            >
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>

                        <li className={!canNextPage ? "disabled" : ""} key="-4">
                            <button
                                style={{ padding: "0" }}
                                type="button"
                                className="waves-effect btn-flat"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                <i
                                    className="material-icons"
                                    style={{ marginRight: "-20px" }}
                                >
                                    chevron_right
                                </i>
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>
                    </ul>
                    <div className="col s12 l3">
                        <label style={{ display: "inline-block" }}>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                        </label>
                    </div>
                    <div className="col s12 l3">
                        <select
                            className="browser-default"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[20, 40, 80].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            <p>{rows.length} Resultados</p>
        </div>
    );
}

export default DataTable;
