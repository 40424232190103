import React, { useEffect } from "react";
import M from "materialize-css";

export default function Modal(props) {
    useEffect(() => {
        const target = props.id
            ? document.querySelector("#" + props.id)
            : document.querySelector(".modal");
        const instance = M.Modal.init(
            target,
            { ...props.options, preventScrolling: false },
            true
        );
        if (props.open) {
            instance.open();
        }
    }, []);

    return (
        <div
            id={props.id ?? ""}
            className="modal modal-fixed-footer"
            style={{ width: "80%" }}
        >
            {props.children}
        </div>
    );
}

export const closeModal = (elementString) => {
    M.Modal.getInstance(document.querySelector(elementString)).close();
};
