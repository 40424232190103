import React, { useContext, useEffect, useRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import ActionContext from "./ActionContext";
import GroupContext from "./GroupContext";

export default function Signature({ name, props }) {
    const { onChangeForm } = useContext(GroupContext);

    const actions = useContext(ActionContext);

    const handleEnd = (e) => {
        props.value = api.current.toDataURL();
        onChangeForm(e);
    };

    const prop = {
        redrawOnResize: true,
        width: props.width ?? "400",
        height: props.height ?? "400",
        options: {
            minWidth: 0.5,
            maxWidth: 1,
            penColor: "rgb(0, 0, 0)",
            onEnd: handleEnd,
            backgroundColor: "rgb(255,255,255)",
        },
    };

    const api = useRef();

    const setValue = () => {
        if (props.value && props.value != "") {
            api.current.fromDataURL(props.value);
        }
    };

    const clear = (e) => {
        api.current.clear();
        props.value = "";
        onChangeForm();
    };

    useEffect(() => {
        if (props.actions) {
            for (let i = 0; i < props.actions.length; i++) {
                console.log("agregare un listener");
                actions.addListener(
                    props.actions[i].element,
                    props.actions[i].action,
                    "form-" +
                        name
                            .toLowerCase()
                            .replace("*", "")
                            .replaceAll(" ", "_"),
                    "signature"
                );
            }
        }
        setValue();
    }, [api]);

    return (
        <div
            className={
                "row form-signature form-" +
                name.toLowerCase().replace("*", "").replaceAll(" ", "_") +
                " " +
                (props.cssClass ?? "")
            }
        >
            <span>{props.label ?? name}</span>
            <div className="col s12 center-align">
                <SignaturePad
                    canvasProps={{ style: { border: "1px solid" } }}
                    key={name}
                    {...prop}
                    ref={api}
                />
            </div>
            <div className="col s12 center-align">
                <button
                    className="btn grey darken-2 white-text"
                    type="button"
                    onClick={clear}
                >
                    Clear
                </button>
            </div>
        </div>
    );
}
