import React from "react";
import { Link } from "react-router-dom";
import "../css/dropdown.css";

export default function Dropdown({
    childrens,
    name,
    keydom,
    setTitle = (e) => {
        return false;
    },
}) {
    const showChilds = (e) => {
        const target = e.target.nextElementSibling;
        let key = Object.keys(target.classList).filter(
            (k) => target.classList[k] == "hide"
        );

        if (key[0]) {
            target.classList.remove("hide");
        } else {
            target.classList.add("hide");
        }
    };

    return (
        <React.Fragment>
            <span
                key={keydom}
                style={{ cursor: "pointer" }}
                onClick={showChilds.bind(this)}
                className="dropdown-parent"
            >
                {name}
            </span>
            <ul className="menu-dropdown hide" style={{ zIndex: "2" }}>
                {Object.entries(childrens).map(([key, val]) => (
                    <li key={key} onClick={setTitle.bind(val.nombre)}>
                        <Link to={val.url}>{val.nombre}</Link>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}
