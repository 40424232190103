import React from "react";
class Home extends React.Component {
    render() {
        return (
            <>
                <p>Home</p>
            </>
        );
    }
}

export default Home;
