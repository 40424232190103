import React, { useEffect, useState } from "react";

export default function Radio({ name, props, layout = undefined }) {
    const handleChange = (e) => {
        const key = e.target.dataset.keyarray;

        props.objects.forEach((element, keyarray) => {
            if (keyarray == key) {
                props.objects[keyarray].checked = true;
            } else {
                props.objects[keyarray].checked = false;
            }
        });
    };

    if (layout) {
        const layouts = {
            Mesa: Mesa,
        };
        const DynamicLayout = layouts[layout];

        if (DynamicLayout) {
            return (
                <DynamicLayout
                    data={props.objects}
                    handleChange={handleChange}
                    name={props.name}
                />
            );
        }
    }

    return (
        <div
            className={
                "row form-radio form-" +
                props.name.toLowerCase().replace(" ", "_")
            }
            key={name}
        >
            <label>{props.label ?? name}</label>
            <div
                className="input-field col s12"
                style={{ display: "flex", flexWrap: "wrap" }}
            >
                {props.objects.map((checkbox, key) => {
                    let prop = {
                        type: "radio",
                        id: checkbox.id ?? checkbox.label,
                        checked: checkbox.checked ?? false,
                        value: checkbox.value,
                        name: props.name ?? name,
                        ...(checkbox.aditionalProps ?? {}),
                    };

                    if (props.required) {
                        prop["required"] = true;
                    }

                    prop["data-parent"] = name;
                    prop["data-keyarray"] = key;

                    return (
                        <p key={key}>
                            <label key={key}>
                                <input {...prop} onChange={handleChange} />
                                <span>{checkbox.label}</span>
                            </label>
                        </p>
                    );
                })}
            </div>
        </div>
    );
}

function Mesa(props) {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const object = {};

        Object.entries(props.data).forEach(([key, value]) => {
            if (!object[value.aditionalProps["data-mesa"]]) {
                object[value.aditionalProps["data-mesa"]] = [];
            }
            object[value.aditionalProps["data-mesa"]].push({
                keyarray: key,
                prop: {
                    "data-keyarray": key,
                    type: "radio",
                    id: value.id ?? value.label,
                    value: value.value,
                    ...(value.aditionalProps ?? {}),
                },
                label: value.label,
            });
        });

        setData(object);
    }, [props.data]);

    if (!data) {
        return "...";
    }

    return (
        <div
            className={
                "row form-radio form-" +
                props.name.toLowerCase().replace(" ", "_")
            }
        >
            {Object.entries(data).map(([mesa, objects]) => {
                const rows = Math.ceil(objects.length / 12);
                const cols = Math.round(objects.length / rows);
                const widthCheck = 100 / cols;
                return (
                    <div
                        key={mesa}
                        className={"card col s" + cols}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <h5 style={{ width: "100%" }} className="card-title">
                            {mesa}
                        </h5>
                        {objects.map((e) => {
                            return (
                                <p
                                    key={e.keyarray}
                                    style={{ width: widthCheck + "%" }}
                                >
                                    <label>
                                        <input
                                            {...e.prop}
                                            checked={
                                                props.data[e.keyarray]
                                                    .checked ?? false
                                            }
                                            onChange={props.handleChange}
                                        />
                                        <span>{e.label}</span>
                                    </label>
                                </p>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
