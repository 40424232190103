import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { downloadBlob } from "../../helpers";
import DataTable from "../DataTable";
import HeaderDocuments from "./header";

export default function List(props) {
    const [filters, setFilters] = useState(undefined);

    const [data, setData] = useState(undefined);

    const [categories, setCategories] = useState(undefined);

    const [current, setCurrent] = useState();

    const fetchData = async () => {
        const response = await axios.get("/document/list");

        if (response.status === 200) {
            transformData(response.data, setData);
        }
        fetchCategories();
    };

    const fetchCategories = async () => {
        const response = await axios.get("/document/categories");

        if (response.status === 200) {
            setCategories(response.data);
            if (!filters) {
                setFilters([
                    {
                        id: "categoria",
                        value: response.data[0].nombre,
                    },
                ]);
            }
        }
    };

    const handleClickCategories = (id) => {
        setFilters([
            {
                id: "categoria",
                value: id,
            },
        ]);
    };

    const deleteFile = async (id) => {
        try {
            const response = await axios.post("/document/delete", {
                id: id,
            });

            if (response.data === true) {
                setData(
                    data.filter((e) => {
                        return e.ref !== id;
                    })
                );
            }
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!data && !filters) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className="container">
                <HeaderDocuments
                    setCurrent={setCurrent}
                    current={current}
                    fetchData={fetchData}
                />
                <div className="row" style={{ display: "flex" }}>
                    {categories &&
                        filters &&
                        categories.map((e) => (
                            <div
                                className="col s6 m4 l2"
                                key={e.id_docs_categoria}
                            >
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={
                                        "card-panel " +
                                        (filters[0].value == e.nombre
                                            ? "orange black-text"
                                            : "black orange-text")
                                    }
                                    onClick={() =>
                                        handleClickCategories(e.nombre)
                                    }
                                >
                                    {e.nombre +
                                        (e.documentos_count > 0
                                            ? " (" + e.documentos_count + ")"
                                            : "")}
                                </div>
                            </div>
                        ))}
                </div>

                <DataTable
                    filterobj={filters}
                    data={data}
                    columns={[
                        {
                            Header: "ref",
                            accessor: "ref",
                            Cell: (props) => {
                                return "DOC " + props.row.values.ref;
                            },
                        },
                        {
                            Header: "Nombre",
                            accessor: "nombre",
                        },
                        {
                            Header: "Categoria",
                            accessor: "categoria",
                        },
                        {
                            Header: "Descripcion",
                            accessor: "descripcion",
                        },
                        {
                            Header: "Espacio",
                            accessor: "espacio",
                        },
                        {
                            Header: "Actividad",
                            accessor: "actividad",
                        },
                        {
                            Header: "Fecha",
                            accessor: "fecha",
                            Cell: (props) => {
                                const value = props.row.values.fecha;
                                return moment(new Date(value)).format(
                                    "DD/MM/YYYY"
                                );
                            },
                        },
                        {
                            Header: "Tamaño",
                            accessor: "tamano",
                        },
                        {
                            Header: "Formato",
                            accessor: "formato",
                        },
                        {
                            Header: "Accion",
                            Cell: (props) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <i
                                            style={{ cursor: "pointer" }}
                                            className="material-icons green-text"
                                            onClick={async () => {
                                                const response =
                                                    await axios.get(
                                                        "/document/download/" +
                                                            props.row.values
                                                                .ref,
                                                        {
                                                            responseType:
                                                                "blob",
                                                        }
                                                    );
                                                downloadBlob(response);
                                            }}
                                        >
                                            download
                                        </i>
                                        <i
                                            style={{ cursor: "pointer" }}
                                            className="material-icons green-text modal-trigger"
                                            data-target="documentosForm"
                                            onClick={() =>
                                                setCurrent(props.row.values.ref)
                                            }
                                        >
                                            edit_note
                                        </i>
                                        <i
                                            className="material-icons red-text"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                deleteFile(props.row.values.ref)
                                            }
                                        >
                                            delete
                                        </i>
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            </div>
        </>
    );
}

const transformData = (data, fn) => {
    const retorno = data.map((d) => {
        return {
            ref: d.id_documento,
            nombre: d.title,
            categoria: d.categoria.nombre,
            descripcion: d.descripcion,
            espacio: d.espacio?.nombre,
            actividad: d.actividad?.nombre,
            fecha: d.created_at,
            tamano: d.size,
            formato: d.dtype.nombre,
        };
    });

    fn(retorno);
};
