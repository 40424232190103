import React from "react";

export const Actions = {
    hide: (selector) => {
        console.log(selector);
        if (selector.classList.contains("hide")) {
            selector.classList.remove("hide");
        } else {
            selector.classList.add("hide");
        }
    },
};
