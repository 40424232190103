import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import Actividad from "./actividades/Actividad";
import Home from "../vistas/Home";
import Login from "../vistas/Login";
import NotFound from "../vistas/NotFound";
import M from "materialize-css";
import axios from "axios";
import { UserProvider } from "../contextos/UserContext";
import List from "./documentos/List";

class App extends React.Component {
    constructor() {
        super();
        let user;
        try {
            user = JSON.parse(localStorage.getItem("user"));
        } catch (error) {}
        this.state = {
            user: user,
            loading: false,
        };
    }

    componentDidMount() {
        axios.defaults.baseURL = "https://casaseatapi.relevance.pro/";
        const resetUser = () => {
            this.setState(
                {
                    user: null,
                },
                () => {
                    localStorage.removeItem("user");
                }
            );
        };
        axios.interceptors.request.use(
            function (successfulReq) {
                return successfulReq;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if ([401, 403].includes(error.response?.status)) {
                    resetUser();
                    return error;
                } else {
                    return Promise.reject(error);
                }
            }
        );
        if (this.state.user?.user?.token) {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + this.state.user.user.token;
        } else {
            this.setState({
                user: null,
            });
        }
        M.AutoInit();
    }

    handleLogin = (e) => {
        localStorage.setItem("user", e);
        const userToJSON = JSON.parse(e);
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + userToJSON.user.token;
        this.setState({
            user: userToJSON,
        });
    };

    render() {
        if (!this.state.user?.user?.token && !this.state.user?.permisos?.menu) {
            return <Login auth={this.handleLogin} />;
        }
        return (
            <>
                <UserProvider value={{ ...this.state.user }}>
                    <Rutas menu={this.state.user.permisos.menu} />
                </UserProvider>
            </>
        );
    }
}

function Rutas(props) {
    let components = [];

    const [rutas, setRutas] = useState([]);

    const constructRoutes = (menu) => {
        Object.entries(menu).forEach(([key, menu]) => {
            const component =
                menu.component != null
                    ? menu.component
                    : menu.subMenus[Object.keys(menu.subMenus)[0]].component;

            const DynamicComponent = React.lazy(() => import("/" + component));

            components.push({
                key: key,
                path: menu.url,
                modulo_id: menu.modulo_id,
                component: DynamicComponent,
            });

            setRutas(components);

            if (menu.subMenus) {
                constructRoutes(menu.subMenus);
            }
        });
    };

    useEffect(() => {
        constructRoutes(props.menu);
    }, []);

    return (
        <BrowserRouter>
            <Layout menu={props.menu}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/documentos" component={List} />
                        {rutas.map((component) => {
                            return (
                                <Route
                                    key={component.key}
                                    exact
                                    path={component.path}
                                    component={(props) => (
                                        <component.component
                                            {...props}
                                            modulo={component.modulo_id}
                                        />
                                    )}
                                />
                            );
                        })}
                        <Route
                            key="actividad-route"
                            path="/actividad/:id"
                            component={Actividad}
                        />
                        <Route
                            exact
                            path="/logout/true"
                            render={() => {
                                axios.post("/logout").then(() => {
                                    localStorage.removeItem("user");
                                    document.location.href = "/";
                                });
                            }}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
