import React, { useCallback, useEffect, useRef, useState } from "react";

export const downloadBlob = (response) => {
    let url = window.URL.createObjectURL(
        new Blob([response.data], {
            type: response.headers["content-type"],
        })
    );
    let a = document.createElement("a");
    a.href = url;
    let content = response.headers["content-disposition"].split("filename=")[1];
    let decode = decodeURI(content, "UTF-8");
    a.setAttribute("download", decode);
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(a.href);
};

export const useStateCallback = (initialState) => {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
        cbRef.current = cb; // store current, passed callback in ref
        setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
        // cb.current is `null` on initial render,
        // so we only invoke callback on state *updates*
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
};
