import React, { useEffect } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import Dropdown from "./Dropdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Logo from "../img/theme/logo-cs-white.svg";

function Location(props) {
    const url = useLocation();
    useEffect(() => {
        props.geturl(url);
    }, []);

    useEffect(() => {
        document.querySelector("title").innerText =
            props.name + " | Casa SEAT Desk management v1.0.1";
    }, [props.name]);

    return false;
}

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: props.menu,
            active: undefined,
            title: "Home",
            logout: false,
            route: [],
        };
    }

    geturl = (e) => {
        this.whosActive(this.state.menu, e.pathname);
        this.setState({
            ...this.state,
            url: e.pathname,
        });
    };

    setRoute = async (id) => {
        let route = [];
        const recursiveLoop = async (menu, id) => {
            Object.values(menu).forEach(async (e) => {
                if (e.subMenus) {
                    const search = await this.imHere(e.subMenus, id);
                    if (search) {
                        route.push(e.id);
                        await recursiveLoop(e.subMenus, id);
                    }
                }
                if (e.id == id) {
                    route.push(e.id);
                }
            });
        };
        await recursiveLoop(
            this.state.menu[this.state.active].subMenus ?? [],
            id
        );
        this.setState({ ...this.state, route: route });
    };

    imHere = async (arr, id) => {
        const found = Object.values(arr).filter((e) => {
            return e.id == id;
        });

        if (found.length > 0) {
            return Promise.resolve(true);
        }
        if (arr.subMenus && arr.subMenus.length > 0) {
            return await this.imHere(arr.subMenus, id);
        }

        return Promise.resolve(false);
    };

    whosActive = async (arr, val) => {
        return Promise.all(
            Object.values(arr).filter((arr) => {
                if (arr.url == val) {
                    return true;
                }
                if (arr.subMenus) {
                    this.whosActive(arr.subMenus, val);
                }
                return false;
            })
        ).then((e) => {
            if (e.length > 0) {
                Object.entries(this.state.menu).forEach(
                    async ([key, value]) => {
                        if (value.id == e[0].id) {
                            this.setState(
                                {
                                    active: key,
                                },
                                () => this.setRoute(e[0].id)
                            );
                            return false;
                        }

                        const search = await this.imHere(
                            value.subMenus ?? [],
                            e[0].id
                        );

                        if (search === true) {
                            this.setState(
                                {
                                    active: key,
                                },
                                () => this.setRoute(e[0].id)
                            );
                            return false;
                        }
                    }
                );
                this.setState({
                    ...this.state,
                    title: e[0].nombre,
                });
            }
        });
    };

    logout = async (e) => {
        e.preventDefault();
        const Myswal = withReactContent(Swal);
        const confirmed = await Myswal.fire({
            title: <p>Deseas deslogear ?</p>,
            showDenyButton: true,
            showCloseButton: true,
            icon: "question",
        });

        if (!confirmed.isConfirmed) {
            return false;
        }

        this.setState({
            logout: true,
        });
    };

    render() {
        if (this.state.logout) {
            return <Redirect to="/logout/true" push={true} />;
        }
        return (
            <React.Fragment>
                <Location geturl={this.geturl} name={this.state.title} />
                <nav className="nav-extended">
                    <div className="nav-wrapper">
                        <Link
                            to="/"
                            className="brand-logo"
                            style={{ height: "24px", marginLeft: "20px" }}
                            onClick={() =>
                                this.setState({
                                    ...this.state,
                                    active: undefined,
                                    title: "home",
                                })
                            }
                        >
                            <img src={Logo} height="100%" />
                        </Link>
                        <ul
                            id="nav-mobile"
                            className="right hide-on-med-and-down"
                        >
                            {Object.keys(this.state.menu).map((key) => {
                                return (
                                    <li
                                        key={key}
                                        onClick={() =>
                                            this.setState({
                                                ...this.state,
                                                active: key,
                                                title: this.state.menu[key]
                                                    .nombre,
                                                route: [],
                                            })
                                        }
                                        className={
                                            this.state.active == key
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <Link to={this.state.menu[key].url}>
                                            {this.state.menu[key].nombre}
                                        </Link>
                                    </li>
                                );
                            })}
                            <li key="log">
                                <a
                                    onClick={this.logout}
                                    className="pointer-cursor"
                                >
                                    <i className="material-icons">logout</i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {this.state.title && (
                        <div
                            className="nav-content"
                            style={{ height: "64px", lineHeight: "64px" }}
                        >
                            <h4
                                style={{
                                    display: "inline-block",
                                    marginLeft: "10px",
                                    textTransform: "uppercase",
                                }}
                            >
                                <b>{this.props.name ?? this.state.title}</b>
                            </h4>
                            <ul className="right hide-on-med-and-down">
                                {this.state.active &&
                                    this.state.menu[this.state.active]
                                        .subMenus &&
                                    Object.entries(
                                        this.state.menu[this.state.active]
                                            .subMenus
                                    ).map(([key, val]) => {
                                        return (
                                            <li
                                                key={key}
                                                onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        title: this.state.menu[
                                                            this.state.active
                                                        ].subMenus[key].nombre,
                                                    });
                                                    this.setRoute(val.id);
                                                }}
                                                className={
                                                    this.state.route.includes(
                                                        val.id
                                                    )
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                {!val.subMenus ? (
                                                    <Link
                                                        to={
                                                            this.state.menu[
                                                                this.state
                                                                    .active
                                                            ].subMenus[key].url
                                                        }
                                                    >
                                                        {
                                                            this.state.menu[
                                                                this.state
                                                                    .active
                                                            ].subMenus[key]
                                                                .nombre
                                                        }
                                                    </Link>
                                                ) : (
                                                    <Dropdown
                                                        setTitle={(e) => {
                                                            this.setState({
                                                                title: e,
                                                            });
                                                        }}
                                                        keydom={val.id}
                                                        childrens={val.subMenus}
                                                        name={val.nombre}
                                                    />
                                                )}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                </nav>
            </React.Fragment>
        );
    }
}

export default Navbar;
