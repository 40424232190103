import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer" style={{ height: "50px" }}>
        <p>footer</p>
      </div>
    );
  }
}

export default Footer;
