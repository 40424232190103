import React from "react";
import moment from "moment";

const pickerFunctions = {
    minDate: (string) => {
        return moment(string).toDate();
    },
    maxDate: (string) => {
        return moment(string).toDate();
    },
    disableDayFn: (obj) => {
        if (typeof obj != "object") {
            return (e) => [1, 2].includes(e.getDay());
        }

        return (date) => {
            const functions = {
                weekDays: (array, date) => {
                    if (!Array.isArray(array) || !(date instanceof Date)) {
                        return false;
                    }
                    return array.includes(date.getDay());
                },
                yearDays: (array, date) => {
                    if (!Array.isArray(array) || !(date instanceof Date)) {
                        return false;
                    }
                    const dayOfYear = moment(date).dayOfYear();
                    return array.includes(dayOfYear);
                },
                monthDay: (array, date) => {
                    if (!Array.isArray(array) || !(date instanceof Date)) {
                        return false;
                    }

                    return array.includes(date.getDate());
                },
            };

            const result = Object.entries(obj).filter(([key, value]) => {
                if (typeof functions[key] !== "function") {
                    return false;
                }

                return functions[key](value, date);
            });

            return result.length > 0;
        };
    },
};

export const DatepickerInstanceParseProperties = async (obj) => {
    const parsed = {};

    Object.keys(obj).forEach((key) => {
        if (typeof pickerFunctions[key] === "function") {
            parsed[key] = pickerFunctions[key](obj[key]);
        } else {
            parsed[key] = obj[key];
        }
    });

    return Promise.resolve(parsed);
};
