import React from "react";
import GroupContext from "../Formbuilder/GroupContext";
const $ = window.jQuery;
/**
 * for Formbuilder
 */
class SimpleButtonFileUploader extends React.Component {
    static contextType = GroupContext;
    constructor(props) {
        super(props);
        this.state = {
            name: "files",
            options: {},
        };

        this.state.name = props.name;
        for (var key in props) {
            var val = props[key];

            if (
                [
                    "limit",
                    "maxSize",
                    "fileMaxSize",
                    "theme",
                    "listInput",
                    "captions",
                ].indexOf(key) > -1
            )
                this.state.options[key] = val;
            if ("extensions" == key)
                this.state.options[key] = val.replace(/ /g, "").split(",");
            if ("files" == key && val != "")
                this.state.options[key] = JSON.parse(val);
        }
        if (props["disabled"]) this.state.options["limit"] = 0;
    }

    componentDidMount() {
        this.$el = $(this.el);
        const state = this.state;
        let { changeFile } = this.context;
        this.$el.fileuploader(
            $.extend(this.state.options, {
                limit: 1,
                enableApi: true,
                onRemove: function (
                    item,
                    listEl,
                    parentEl,
                    newInputEl,
                    inputEl
                ) {
                    if (changeFile) {
                        setTimeout(() => {
                            changeFile(state.name, "");
                        }, 500);
                    }
                    return true;
                },
                onSelect: function (
                    item,
                    listEl,
                    parentEl,
                    newInputEl,
                    inputEl
                ) {
                    if (changeFile) {
                        setTimeout(() => {
                            changeFile(state.name, item.reader.src);
                        }, 500);
                    }
                },
            })
        );
        this.api = $.fileuploader.getInstance(this.$el);
    }

    componentWillUnmount() {
        if (this.api) this.api.destroy();
    }

    render() {
        return (
            <input
                type="file"
                name={this.state.name}
                ref={(el) => (this.el = el)}
            />
        );
    }
}

export default SimpleButtonFileUploader;
