import React from "react";
import RichText from "../componentes/RichText";
import SimpleButtonFileUploader from "../Fileuploader/SimpleButtonFileUploader";
import Checkbox from "./Checkbox";
import Datepicker from "./Datepicker";
import Group from "./Group";
import Radio from "./Radio";
import Range from "./Range";
import Select from "./Select";
import Signature from "./Signature";
import Text from "./Text";
import Textarea from "./Textarea";
import Timepicker from "./Timepicker";
import TimepickerMaterial from "./TimepickerMaterial";
import View from "./View";

export const Inputs = {
    textArea: (name, props, controlFunct = () => false) => {
        return (
            <Textarea
                key={name}
                name={name}
                props={props}
                handler={controlFunct}
            />
        );
    },
    text: (name, props, controlFunct = () => false) => {
        return (
            <Text key={name} name={name} props={props} handler={controlFunct} />
        );
    },
    select: (name, props, controlFunct = () => false) => {
        return (
            <Select
                key={name}
                name={name}
                props={props}
                handler={controlFunct}
            />
        );
    },
    checkbox: (name, props, controlFunct = () => false) => {
        return <Checkbox key={name} name={name} props={props} />;
    },
    radio: (name, props, controlFunct = () => false) => {
        return (
            <Radio
                key={name}
                name={name}
                props={props}
                handler={controlFunct}
                layout={props.layout ?? undefined}
            />
        );
    },
    datepicker: (name, props, controlFunct = () => false) => {
        return (
            <Datepicker
                key={name}
                name={name}
                props={props}
                handler={controlFunct}
            />
        );
    },
    timepicker: (name, props, controlFunct = () => false) => {
        return (
            <Timepicker
                key={name}
                name={name}
                props={props}
                handler={controlFunct}
            />
        );
    },
    TimepickerMaterial: (name, props, controlFunct = () => false) => {
        return <TimepickerMaterial name={name} props={props} />;
    },
    range: (name, props, controlFunct = () => false) => {
        return (
            <Range
                name={name}
                props={props}
                key={name}
                onChangeHandler={controlFunct}
            />
        );
    },
    group: (name, props) => {
        return <Group name={name} props={props} key={name} />;
    },
    richtext: (name, props) => {
        return (
            <div className="row" key={name}>
                <div className="col s12">
                    <span>{name}</span>
                    <RichText
                        id={props.id}
                        value={props.value}
                        isControlled={false}
                        onChange={(e) => {
                            props.value = e;
                        }}
                        {...(props.richProps ?? {})}
                    />
                </div>
            </div>
        );
    },
    file: (name, props) => {
        return <SimpleButtonFileUploader name={name} {...props} />;
    },
    signature: (name, props) => {
        return <Signature name={name} props={props} />;
    },
    view: (name, props) => {
        return <View id={name} {...props} />;
    },
};
