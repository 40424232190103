import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/richText.css";
import { forceChange } from "../Formbuilder/instances";

export default function RichText({
    id,
    value = "",
    onChange = (e) => {},
    toolbar = undefined,
    keyboard = {},
    history = {},
    clipboard = {},
    formats = undefined,
    theme = "snow",
    isControlled = true,
}) {
    const [valueText, setValueText] = useState(value);

    const [module, setModule] = useState({
        toolbar: toolbar ?? [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["clean"],
        ],
        keyboard: {
            ...keyboard,
        },
        history: {
            ...history,
        },
        clipboard: {
            matchVisual: false,
            ...clipboard,
        },
    });

    const [format, setFormat] = useState(
        formats ?? [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
        ]
    );
    const handleChange = (e) => {
        const element = document.getElementById(id);
        const value = e;
        forceChange(element, value);
        setValueText(e);
    };

    const properties = {
        modules: module,
        theme: theme,
        formats: format,
        onChange: handleChange,
    };

    if (isControlled) {
        properties.value = valueText;
    } else {
        properties.defaultValue = valueText;
    }

    useEffect(() => {
        const selects = document.querySelectorAll(".quill select");
        selects.forEach((e) => {
            e.classList.add("browser-default");
        });
    });

    return (
        <>
            <input
                id={id}
                value={valueText}
                type="text"
                style={{ display: "none" }}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            />
            <ReactQuill {...properties} />
        </>
    );
}
